import React from "react";
import PropTypes from "prop-types";
import { inputType } from "../../../Constants/InputType";

function UILabelInput(props) {
    const getInputMarkupBasedOnType = () => {
        switch (props.inputType) {
            case inputType.TEXT:
                return <input name={props.inputId} id={props.inputId} type="text" className="form-control"
                    required={props.required} disabled={props.disabled} defaultValue={props.defaultValue} />;
            case inputType.PASSWORD:
                return <input name={props.inputId} id={props.inputId} type="password" className="form-control"
                    required={props.required} disabled={props.disabled} />;
            case inputType.EMAIL:
                return <input name={props.inputId} id={props.inputId} type="email" className="form-control"
                    required={props.required} disabled={props.disabled} defaultValue={props.defaultValue} />;
            case inputType.NUMBER:
                return <input name={props.inputId} id={props.inputId} type="number"
                    step={props.numberStep ? props.numberStep : "1"} className="form-control"
                    required={props.required} disabled={props.disabled} defaultValue={props.defaultValue} min={props.numberMin} max={props.numberMax} />;
            case inputType.SELECT:
                return <select className="form-select" id={props.inputId} name={props.inputId} required={props.required} disabled={props.disabled}
                    defaultValue={props.defaultValue}>
                    {props.selectOptions.map((option, i) => {
                        return <option key={i} value={option.value}>{option.display}</option>
                    })}
                </select>;
            case inputType.DATE:
                return <input name={props.inputId} id={props.inputId} type="date" className="form-control"
                required={props.required} disabled={props.disabled} defaultValue={props.defaultValue} />;
            default:
                return <></>;
        }
    }

    return (
        <div className="row mt-2">
            <div className="col-sm-4">
                <label className={`form-label ${props.labelClass}`} htmlFor={props.inputId}>{props.labelText}:{props.required && <span className="text-danger">*</span>}</label>
            </div>
            <div className="col-sm-8">
                {getInputMarkupBasedOnType()}
                {props.invalidFeedback && <div className="text-danger" style={{ fontSize: "12px", marginLeft: "2px" }}>{props.invalidFeedback}</div>}
            </div>
        </div>
    )
}

UILabelInput.propTypes = {
    inputType: PropTypes.number,
    labelText: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    inputId: PropTypes.string,
    defaultValue: PropTypes.string,
    invalidFeedback: PropTypes.string,
    labelClass: PropTypes.string,
    numberStep: PropTypes.string,
    selectOptions: PropTypes.array
}

export default UILabelInput;