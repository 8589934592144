import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function GameTagDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Game Tag Details"}
                            editHref={"/game-tag/update"}
                            endpoint={"game-tag/read"}
                            relations={["game_configs", "game_configs.operator", "game_configs.provider"]}
                            oneToManyRelations={[
                                {
                                    title: "Game Configs",
                                    columnTitles: [
                                        "ID", "Name", "Internal ID", "Operator", "Provider", "Provider ID"
                                    ],
                                    name: "game_configs",
                                    objectKeys: ["id", "name", "internal_game_id", "operator.company_name", "provider.name", "provider_game_id"],
                                    detailsHref: "/game-config/details"
                                }
                            ]} />
}

export default GameTagDetailsPage;