import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function ProviderListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Provider"}
                            deleteModalQuestion={"Are you sure you want to delete this provider?"}
                            editHref={"/provider/update"}
                            detailsHref={"/provider/details"}
                            objectKeys={["id", "name", "agregator_id", "game_launch_url"]}
                            columnTitles={[
                                "ID", "Name", "Agregator ID", "Game Launch URL"
                            ]}
                            insertHref={"/provider/create"}
                            insertTitle={"Add Provider"}
                            pageTitle={"Providers"}
                            deleteEndpoint={"provider/delete"}
                            readEndpoint={"provider/read"}
                            />
        </>
    )
}

export default ProviderListPage;