import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faSortUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { styleType } from "../../../Constants/StyleType";
import UIButton from "../Button/Button";
import { faSortDown } from "@fortawesome/free-solid-svg-icons/faSortDown";
import "./style.css";

function UITable(props) {
    const [checkedIds, setCheckedIds] = useState([]);
    const getValue = (value, key) => {
        let val = "";
        if (key.includes(".")) {
            let keys = key.split(".");
            val = value[keys[0]];

            if (!val) return "";

            for (let i = 1; i < keys.length; i++) {
                val = val[keys[i]];
                if (!val) return "";
            }
        } else {
            val = value[key];
        }

        if (!isNaN(Number(val))) {
            val = Number(val).toString();
        }

        return val;
    }

    const checkBoxChanged = (e, id) => {
        if (props.onlyOneSelected) {
            if (e.target.checked) {
                setCheckedIds([id]);
                var checks = document.getElementsByClassName("tb-check")
                for (var i = 0; i < checks.length; i++) {
                    if (checks[i] !== e.target) {
                        checks[i].checked = false;
                    }
                }
            } else {
                setCheckedIds([]);
            }
        }

        let checkedIdsClone = structuredClone(checkedIds);
        if (e.target.checked && !checkedIds.includes(id)) {
            checkedIdsClone.push(id);
        } else if (!e.target.checked && checkedIds.includes(id)) {
            const i = checkedIdsClone.indexOf(id);
            checkedIdsClone.splice(i, 1);
        }

        setCheckedIds(checkedIdsClone);
    }

    return (
        <div className="table-responsive">
            {props.includeCheckMarks && 
                <input type="hidden" name={props.checkMarksValueId} id={props.checkMarksValueId} value={checkedIds} />
            }
            <table className="table align-middle">
                <thead className="table-dark bg-fade-black">
                    <tr>
                        {props.includeCheckMarks && <th></th>}
                        {props.columnTitles.map((value, index) => {
                            return <th style={{
                                cursor: props.sortingChanged && !props.objectKeys[index].includes(".") ? "pointer" : "default"
                            }} scope="col" key={`th-${index}`} onClick={props.sortingChanged && !props.objectKeys[index].includes(".") ? () => props.sortingChanged(props.objectKeys[index]) : () => {return;}}>
                                {value} {props.sortState && props.sortState.sortBy === props.objectKeys[index] && 
                                    props.sortState.direction === "desc" ? <FontAwesomeIcon icon={faSortDown} /> : 
                                    
                                      props.sortState && props.sortState.sortBy === props.objectKeys[index] && 
                                    props.sortState.direction === "asc" ? <FontAwesomeIcon icon={faSortUp} /> : null
                                    
                                }
                            </th>
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody className="table-striped">
                    {props.data.map((value, index) => {
                        return <tr key={`row-${index}`} ref={index == props.data.length - 1 ? props.lastElementRef : null}>
                            {props.includeCheckMarks && <td>
                                    <input type="checkbox" className="form-check-input tb-check" onChange={(e) => checkBoxChanged(e, value.id)}></input>
                                </td>}
                            {props.objectKeys.map((objKey, objIndex) => {
                                return <td key={`col-${index}-${objIndex}`}>
                                    {getValue(value, objKey)}
                                </td>
                            })}
                            <td><span className="table-btns">{props.preventDelete ? <></> : <UIButton type="button"
                                styleType={styleType.FADEBLACK}
                                additionalClasses={"ms-1"}
                                icon={<>
                                    <FontAwesomeIcon icon={faTrash} style={{ color: "#fff" }} /></>
                                }
                                onClick={() => props.deleteClicked(value.id)} />}
                                {props.editHref && <Link to={`${props.editHref}${props.editHref.includes('?') ? "&" : "?"}id=${value.id}`} className="btn btn-fadeblack ms-1"> <FontAwesomeIcon icon={faPen} style={{ color: "#fff" }} /> </Link>}
                                {props.detailsHref && <Link to={`${props.detailsHref}?id=${value.id}`} className="btn btn-fadeblack ms-1"> <FontAwesomeIcon icon={faEye} style={{ color: "#fff" }} /> </Link>}
                            </span>
                            </td>

                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default UITable;