import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function CurrencyDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Currency Details"}
                            editHref={"/currency/update"}
                            endpoint={"currency/read"} />
}

export default CurrencyDetailsPage;