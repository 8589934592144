import React, { useEffect, useRef, useState } from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../Domain/Constants/StyleType";
import UIModal from "../../../Domain/Components/UI/Modal/Modal";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { getAllActive } from "../../../Infrastructure/Services/DataService";
import UIMultiselect from "../../../Domain/Components/UI/Multiselect/Multiselect";
import { postApiCall } from "../../../Infrastructure/Helpers/HttpHandler";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function RoleDetailsPage(props) {
    const [attachPermissionModalOpen, setAttachPermissionModalOpen] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [failedToLoadPermissions, setFailedToLoadPermissions] = useState(false);
    const navigate = useNavigate()
    const detailsPageComponentRef = useRef({});

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);

    const openAttachModal = async () => {
        if (permissions === null) {
            const token = getWithExpiry("token");
            if (!token) {
                props.setLoggedIn(false);
                return;
            }
            let result = await getAllActive("permission/read", token, true);
            if (result === null) {
                setFailedToLoadPermissions(true);
            } else {
                setPermissions(result);
            }
        }

        setAttachPermissionModalOpen(true);
    }

    const onAttach = async () => {
        let idsElement = document.getElementById("permission_ids");
        const token = getWithExpiry("token");
        if (!token) {
            props.setLoggedIn(false);
            return;
        }
        try {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            let id = params.get('id');
            var body = {
                roleId: parseInt(id),
                permissionIds: idsElement.value.split(",")
            }
            var result = await postApiCall("role/attach-permissions", body, token);

            if (result.data) {
                setAttachPermissionModalOpen(false);
                detailsPageComponentRef.current.loadData();
            } else {
                if (result.error) {
                    toast.error(result.error.message);
                } else {
                    toast.error("Failed to attach permission");
                }
            }
        } catch (ex) {
            toast.error(ex);
        }
    }

    const onDetach = async () => {
        let idsElement = document.getElementById("detach-permissions-ids");
        const token = getWithExpiry("token");
        if (!token) {
            props.setLoggedIn(false);
            return;
        }
        try {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            let id = params.get('id');
            var body = {
                roleId: parseInt(id),
                permissionIds: idsElement.value.split(",")
            }
            var result = await postApiCall("role/detach-permissions", body, token);

            if (result.data) {
                detailsPageComponentRef.current.loadData();
            } else {
                if (result.error) {
                    toast.error(result.error.message);
                } else {
                    toast.error("Failed to detach permission");
                }
            }
        } catch (ex) {
            toast.error(ex);
        }
    }

    return <> <GenericDetailsPage setLoggedIn={props.setLoggedIn}
        title={"Role Details"}
        editHref={"/role/update"}
        endpoint={"role/read"}
        withoutIsActiveFilter={true}
        relations={["permissions"]}
        manyToManyRelations={[{
            name: "permissions",
            title: "Permissions",
            attachHref: {
                custom:<div> 
                    <UIButton styleType={styleType.FADEBLACK} buttonText={"Detach Selected Permissions"} buttonType={"button"} additionalClass="me-2" onClick={onDetach}/>
                    <UIButton styleType={styleType.FADEBLACK} buttonText={"Attach Permission"} buttonType={"button"} onClick={openAttachModal} /> 
                </div>
            },
            columnTitles: ["Name", "Guard Name"],
            objectKeys: ["name", "guard_name"],
            detailsHref: "/permission/details",
            includeCheckMarks: true,
            checkMarksValueId: "detach-permissions-ids"
        }]}
        onlyReadForAdmin={true}
        ref={detailsPageComponentRef} />
        <UIModal showModal={attachPermissionModalOpen}
            onClose={() => setAttachPermissionModalOpen(false)}
            onConfirm={onAttach}
            title={"Attach permission"}
            body={<>
                {failedToLoadPermissions ? <p className="text-danger">Failed to load available permissions</p> :
                    <UIMultiselect id="permission_ids" options={permissions} displayKey={"name"} />
                }
            </>}
            confirmButtonText={"Attach"} />
    </>
}

export default RoleDetailsPage;