import React, { useEffect } from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";

function PermissionListPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);

    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            detailsHref={"/permission/details"}
                            objectKeys={["id", "name", "guard_name"]}
                            columnTitles={[
                                "ID", "Name", "Guard Name"
                            ]}
                            insertHref={"/permission/create"}
                            insertTitle={"Add Permission"}
                            pageTitle={"Permissions"}
                            readEndpoint={"permission/read"}
                            withoutIsActiveFilter={true}
                            disableDelete={true}
                            onlyReadForAdmin={true}
                            />
        </>
    )
}

export default PermissionListPage;