import React, { useEffect, useState } from "react";
import "./style.css";
import UILabelInput from "../../../Domain/Components/UI/LabelInput/LabelInput";
import { inputType } from "../../../Domain/Constants/InputType";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../Domain/Constants/StyleType";
import { loginCall } from "../../../Infrastructure/Services/AuthService";
import logo from "../../Assets/Images/flexGamesLogo.png"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function LoginPage(props) {
    const [loginFailed, setLoginFailed] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.loggedIn) {
            navigate("/currency");
            toast.dismiss();
        }
    }, [])

    const loginSubmit = async(e) => {
        e.preventDefault()
        
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        try {
            var response = await loginCall(formJson.email, formJson.password);
            if (response.data === null) {
                setLoginFailed(true);
            } else {
                props.setLoggedIn(true);
            }

        } catch (ex) {
            toast.error(ex);
            setLoginFailed(true);
        }
    }

    return (
        <div className="row login-wrapper">
            <div className="col-md-6 offset-md-3 login-col">
                <form className="login-form" method="POST" onSubmit={loginSubmit}>
                    <div className="logo-wrapper">
                    <img src={logo} alt="brand" className="brand-img" />
                    </div>
                    <div className="login-inputs ms-2 me-2 pb-2">
                        <UILabelInput inputType={inputType.EMAIL}
                                      labelText={"Email"}
                                      disabled={false}
                                      required={true}
                                      inputId={"email"}
                                      invalidFeedback={loginFailed ? "Invalid username or password" : null}
                                      labelClass={"text-light"} />
                        <UILabelInput inputType={inputType.PASSWORD}
                                      labelText={"Password"}
                                      disabled={false}
                                      required={true}
                                      inputId={"password"}
                                      labelClass={"text-light"} />
                        <div className="d-flex justify-content-end mt-2">
                            <UIButton styleType={styleType.FLEXPINK}
                                        buttonType={"submit"}
                                        buttonText={"LOGIN"} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage;