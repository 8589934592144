import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function OperatorDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Operator Details"}
                            editHref={"/operator/update"}
                            endpoint={"operator/read"}
                            relations={["operator", "game_configs", "casinos", "game_configs.provider"]}
                            oneToManyRelations={[
                                {
                                    title: "Game Configs",
                                    columnTitles: [
                                        "ID", "Name", "Internal ID", "Provider", "Provider ID"
                                    ],
                                    name: "game_configs",
                                    objectKeys: ["id", "name", "internal_game_id", "provider.name", "provider_game_id"],
                                    detailsHref: "/game-config/details"
                                },
                                {
                                    title: "Casinos",
                                    columnTitles: [
                                        "ID", "Company Name", "Partner ID", "Type", "Lobby URL", "Email"
                                    ],
                                    objectKeys: ["id", "company_name", "partner_id", "type", "lobby_url", "email"],
                                    name: "casinos",
                                    detailsHref: "/operator/details"
                                }
                            ]} />
}

export default OperatorDetailsPage;