import React, { useCallback, useEffect, useRef, useState } from "react";
import UITable from "../../../../Domain/Components/UI/Table/Table";
import usePagination from "../../../../Infrastructure/Hooks/usePagination";
import { Link } from "react-router-dom";
import UISearchBar from "../../../../Domain/Components/UI/SearchBar/SearchBar";
import UIModal from "../../../../Domain/Components/UI/Modal/Modal";
import { getWithExpiry } from "../../../../Domain/Helpers/LocalStorageHelper";
import { deleteById } from "../../../../Infrastructure/Services/DataService";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function GenericListPage(props) {
    const [sortState, setSortState] = useState({ sortBy: 'id', direction: 'desc' })
    const [searchValues, setSearchValues] = useState({});
    const [page, setPage] = useState(1)
    const [sortChangedOnFirstPage, setSortChangedOnFirstPage] = useState(0);
    const { data, more, loading, error } = usePagination(page, 100, props.readEndpoint, sortState, sortChangedOnFirstPage, props.setLoggedIn, props.relations ? props.relations : [], props.withoutIsActiveFilter ? false : true, searchValues);
    const [rows, setRows] = useState(data);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteEntityId, setDeleteEntityId] = useState(null);
    const [onlyRead, setOnlyRead] = useState(false);

    useEffect(() => {
        const roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }

        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0 ||
            (!roles.includes("super-admin") && props.onlyReadForAdmin)) {
            setOnlyRead(true);
        }
    }, [])

    useEffect(() => {
        setRows(data);
    }, [data])

    // Observer
    const observer = useRef(); // ref to store observer

    const lastElementRef = useCallback((element) => {
        //element is the react element being referenced

        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();

        // if there's no more data to be fetched, don't set new observer
        if (!more) return;

        // set new observer
        observer.current = new IntersectionObserver((entries) => {
            // increase page number when element enters (is intersecting with) viewport.
            // This triggers the pagination hook to fetch more items in the new page
            if (entries[0].isIntersecting && more) setPage((prev) => prev + 1);
        });

        // observe/monitor last element
        if (element) observer.current.observe(element);
    }, [more]);


    const sortingChanged = (columnKey) => {
        var tmpSortState = {
            sortBy: columnKey,
            direction: sortState.sortBy === columnKey ? sortState.direction === 'desc' ? 'asc' : 'desc' : 'desc'
        }

        setSortState(tmpSortState);
    }

    useEffect(() => {
        if (page === 1) {
            setSortChangedOnFirstPage(sortChangedOnFirstPage + 1);
        }
        setPage(1);
    }, [sortState, searchValues])

    const onSearch = (searchVal) => {
        var searchObj = {};
        props.objectKeys.forEach((key) => {
            if (!key.includes(".")) {
                searchObj[key] = searchVal;
            } else {
                if (!searchObj.relationsSearch) searchObj.relationsSearch = {};
                var keys = key.split(".");
                if (keys.length === 2) {
                    if (!searchObj.relationsSearch[keys[0]]) {
                        searchObj.relationsSearch[keys[0]] = {};
                    }
                    searchObj.relationsSearch[keys[0]][keys[1]] = searchVal;
                }
            }
        })
        setSearchValues(searchObj);
    }

    const onTableDeleteClicked = (id) => {
        setDeleteModalOpen(true)
        setDeleteEntityId(id);
    }

    const onCancelDelete = () => {
        setDeleteModalOpen(false)
        setDeleteEntityId(null);
    }

    const onDelete = async () => {
        if (props.disableDelete) return;
        var token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        var result = await deleteById(props.deleteEndpoint, deleteEntityId, token);

        if (result) {
            setDeleteModalOpen(false);
            setDeleteEntityId(null);
            if (page === 1) {
                setSortChangedOnFirstPage(sortChangedOnFirstPage + 1);
            }
            setPage(1);
        } else {
            toast.error("Failed to delete entity");
        }
    }

    const onSearchInputChange = (e) => {
        const inputVal = e.target.value;
        if (!inputVal || inputVal === "") {
            setSearchValues({});
        }
    }

    return (
        <>
            {data &&
                <div className='container'>
                    <div className='d-flex justify-content-between m-4'>
                        <h3>{props.pageTitle}</h3>
                    </div>
                    <div className='d-flex justify-content-between ms-4 me-4 mb-2'>
                        <UISearchBar onSearch={(val) => onSearch(val)} onInputChange={onSearchInputChange}/>
                        {onlyRead ? <span></span> : <Link className="btn btn-flexpink" to={props.insertHref}>{props.insertTitle}</Link>}
                    </div>
                    <div className='ms-4 me-4'>
                        <UITable columnTitles={props.columnTitles}
                            data={data}
                            objectKeys={props.objectKeys}
                            primaryKey={'id'}
                            lastElementRef={lastElementRef}
                            editHref={onlyRead ? null : props.editHref}
                            detailsHref={props.detailsHref}
                            sortState={sortState}
                            sortingChanged={sortingChanged}
                            deleteClicked={onTableDeleteClicked}
                            preventDelete={props.disableDelete || onlyRead}
                        ></UITable>
                        {loading & more ? <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div> : <span></span>}
                        {!more && !loading && <p className='text-center p-4 text-secondary font-weight-bold'>No more data to be loaded</p>}
                    </div>
                </div>
            }

            {!props.disableDelete && !onlyRead &&
                <UIModal showModal={deleteModalOpen}
                    onClose={onCancelDelete}
                    onConfirm={onDelete}
                    title={props.deleteModalTitile}
                    body={props.deleteModalQuestion}
                    confirmButtonText={"Delete"}
                />
            }
        </>
    )
}

GenericListPage.propTypes = {
    setLoggedIn: PropTypes.func,
    deleteModalTitile: PropTypes.string,
    deleteModalQuestion: PropTypes.string,
    editHref: PropTypes.string,
    detailsHref: PropTypes.string,
    objectKeys: PropTypes.arrayOf(PropTypes.string),
    columnTitles: PropTypes.arrayOf(PropTypes.string),
    insertHref: PropTypes.string,
    insertTitle: PropTypes.string,
    pageTitle: PropTypes.string,
    deleteEndpoint: PropTypes.string,
    readEndpoint: PropTypes.string,
    relations: PropTypes.arrayOf(PropTypes.string),
    withoutIsActiveFilter: PropTypes.bool,
    onlyReadForAdmin: PropTypes.bool
}

export default GenericListPage;