import React, { useEffect } from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";

function UserListPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);

    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete User"}
                            deleteModalQuestion={"Are you sure you want to delete this user?"}
                            editHref={"/user/update"}
                            detailsHref={"/user/details"}
                            objectKeys={["id", "name", "email", "guard"]}
                            columnTitles={[
                                "ID", "Name", "Email", "Guard"
                            ]}
                            insertHref={"/user/create"}
                            insertTitle={"Add User"}
                            pageTitle={"Users"}
                            deleteEndpoint={"user/delete"}
                            readEndpoint={"user/read"}
                            onlyReadForAdmin={true}
                            />
        </>
    )
}

export default UserListPage;