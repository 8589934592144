import React from "react";
import PropTypes from "prop-types"
import UIButton from "../Button/Button";
import { styleType } from "../../../Constants/StyleType";
import ReactDOM from "react-dom";

function UIModal(props) {
    return (
        <> {
            props.showModal && ReactDOM.createPortal(
                <div className="modal d-flex justify-content-center align-items-center" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">{props.title}</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
                            </div>
                            <div className="modal-body">
                                {props.body}
                            </div>
                            <div className="modal-footer">
                                <UIButton buttonText={"Close"} styleType={styleType.SECONDARY} type={"button"} onClick={props.onClose} />
                                <UIButton buttonText={props.confirmButtonText} styleType={styleType.FADEBLACK} type={"button"} onClick={props.onConfirm} />
                            </div>
                        </div>
                    </div>
                </div>, document.getElementById("portal")
            )
        }</>
    )
}

UIModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    confirmButtonText: PropTypes.string
}

export default UIModal;