import React, { useEffect, useState } from "react";
import { getAllActive } from "../../../Infrastructure/Services/DataService";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { toast } from "react-toastify";

function CurrencyRateInsertPage(props) {
    const [currencies, setCurrencies] = useState(null);
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        const token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        getAllActive("currency/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load the available currencies");
            } else {
                setCurrencies(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });
    }, []);

    useEffect(() => {
        if (currencies) {
            setInputs([
                {
                    inputType: inputType.SELECT,
                    labelText: "From",
                    required: true,
                    inputId: "from_currency_id",
                    validationFunction: () => {return null},
                    selectOptions: formatArrayForSelectInput(currencies, "iso_code")
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "To",
                    required: true,
                    inputId: "to_currency_id",
                    validationFunction: () => {return null},
                    selectOptions: formatArrayForSelectInput(currencies, "iso_code")
                },
                {
                    inputType: inputType.NUMBER,
                    labelText: "Value",
                    required: true,
                    inputId: "value",
                    validationFunction: () => {return null},
                    numberStep: "0.00000001"
                },
                {
                    inputType: inputType.DATE,
                    labelText: "Date",
                    required: true,
                    inputId: "date",
                    validationFunction: () => {return null}
                }
            ])
        }
    }, [currencies])

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"currency-rate/create"}
            pageTitle={"Create Currency Rate"}
            isUpdate={false}
            entityName={"Currency rate"} />
    )
}

export default CurrencyRateInsertPage;