import React, { useEffect, useState } from "react";
import { getAllActive } from "../../../Infrastructure/Services/DataService";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { toast } from "react-toastify";

function PlayerInsertPage(props) {
    const [countries, setCountries] = useState(null);
    const [languages, setLanguages] = useState(null);
    const [operators, setOperators] = useState(null);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        getAllActive("country/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load countries");
            } else {
                setCountries(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("language/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load languages");
            } else {
                setLanguages(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("operator/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load operators");
            } else {
                setOperators(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });
    }, []);

    useEffect(() => {
        if (languages && countries && operators) {
            setColumns([
                {
                    inputType: inputType.TEXT,
                    labelText: "Operator User Id",
                    required: true,
                    inputId: "operator_user_id",
                    validationFunction: (value) => validateTextInput(value, "Operator User ID", true)
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Operator",
                    required: true,
                    inputId: "operator_id",
                    selectOptions: formatArrayForSelectInput(operators, "company_name"),
                    validationFunction: () => {return null}
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Country",
                    required: true,
                    inputId: "country_id",
                    selectOptions: formatArrayForSelectInput(countries, "name"),
                    validationFunction: () => {return null}
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Language",
                    required: true,
                    inputId: "language_id",
                    selectOptions: formatArrayForSelectInput(languages, "name"),
                    validationFunction: () => {return null}
                }
            ])
        }
    }, [languages, countries, operators])

    return (
        <GenericInsertUpdatePage inputs={columns}
            setLoggedIn={props.setLoggedIn}
            endpoint={"player/create"}
            pageTitle={"Create Player"}
            isUpdate={false}
            entityName={"Player"} />
    )
}

export default PlayerInsertPage;