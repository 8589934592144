import { useEffect, useState } from "react";
import { getWithExpiry } from "../../Domain/Helpers/LocalStorageHelper";
import { postApiCall } from "../Helpers/HttpHandler";
import { toast } from "react-toastify";

export default function usePagination(page, entriesPerPage, endPoint, sortState, sortChangedOnFirstPage, setLoggedIn, relations = [], onlyActive = true, searchValues) {
    const [data, setData] = useState([]); // stores data retrieved
    const [more, setMore] = useState(true); // indicates if there's more data to be retrieved from the API.
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false); // indicates error existence

    const getData = async () => {
        let token = getWithExpiry("token")
        if (token === null) {
            setLoggedIn(false);
            return;
        }

        let relationsSearch = {};
        let searchVals = searchValues;
        if (searchValues && searchValues.hasOwnProperty("relationsSearch")) {
            relationsSearch = searchValues.relationsSearch;
            delete searchVals.relationsSearch;
        }

        const body = {
            offset: entriesPerPage * (page - 1),
            limit: entriesPerPage,
            order: sortState.direction,
            columnName: sortState.sortBy,
            relations: relations,
            searchValues: searchVals,
            searchRelations: relationsSearch
        }

        if (onlyActive) {
            if (body.values === undefined || body.values === null) {
                body.values = {'is_active': '1'};
            } else {
                body.values = {...body.values, 'is_active': '1'};
            }
        }
        try {
            let resp = await postApiCall(endPoint, body, `Bearer ${token}`);
            if (resp.message) {
                toast.error(resp.message);
                return;
            }

            if (resp.error) {
                toast.error(resp.error.message)
                return;
            }

            if (resp.data !== null && resp.data.success) {
                return resp;
            } 
        } catch(ex) {
            toast.error(ex)
        }
        
    }

    useEffect(() => {
        setData([]);
    }, [sortState, searchValues])

    const fetchData = async () => {
        setError(false);

        // indicate that data-fetching is ongoing
        setLoading(true);

        // make call to API
        let resp = await getData();

        // add fetched data to list. We make use of Set to avoid duplicates
        if (resp && resp.data) {
            resp.data.data.map((row) => {
                if (row.created_at) {
                row.created_at = row.created_at.replace(".000000Z","");
                row.created_at = row.created_at.replace("T"," ");
                }
                if(row.updated_at){
                row.updated_at = row.updated_at.replace(".000000Z","");
                row.updated_at = row.updated_at.replace("T"," ");
                }
            })                
            setData((prev) => [...new Set([...prev, ...resp.data.data])]);
            setMore(resp.data.data.length === entriesPerPage); // set more to true if nextPage exists
            setLoading(false);
        }
        else {
            setError(true); // set error to true if an exception was caught
        }
    }

    useEffect(() => {
        if (sortChangedOnFirstPage === 0 || sortChangedOnFirstPage === 1) {
            return;
        }
        setData([]);

        fetchData().catch(toast.error);
    }, [sortChangedOnFirstPage])
    


    useEffect(() => {
        // Reset error state before loading
        if (page === 1 && data.length > 0) {
            setData([]);
        }

        fetchData().catch(toast.error);
    }, [page]);

    return { data, more, loading, error };
}