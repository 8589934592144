import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function CurrencyRateDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Currency Rate Details"}
                            editHref={"/currency-rate/update"}
                            endpoint={"currency-rate/read"}
                            relations={["currency_from", "currency_to"]} />
}

export default CurrencyRateDetailsPage;