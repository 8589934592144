import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function CurrencyListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Currency"}
                            deleteModalQuestion={"Are you sure you want to delete this currency?"}
                            editHref={"/currency/update"}
                            detailsHref={"/currency/details"}
                            objectKeys={["id", "name", "iso_code"]}
                            columnTitles={[
                                "ID", "Name", "ISO Code"
                            ]}
                            insertHref={"/currency/create"}
                            insertTitle={"Add Currency"}
                            pageTitle={"Currencies"}
                            deleteEndpoint={"currency/delete"}
                            readEndpoint={"currency/read"}/>
        </>
    )
}

export default CurrencyListPage;