import React, { useEffect, useState } from "react";
import { getAllActive } from "../../../Infrastructure/Services/DataService";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { operatorTypeSelect } from "../../../Domain/Constants/OperatorTypeSelect";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import { toast } from "react-toastify";

function OperatorUpdatePage(props) {
    const [operators, setOperators] = useState(null);
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        const token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        getAllActive("operator/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load operators");
            } else {
                setOperators(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });
    }, []);

    useEffect(() => {
        if (operators) {
            setInputs([
                {
                    inputType: inputType.TEXT,
                    labelText: "Company Name",
                    required: true,
                    inputId: "company_name",
                    validationFunction: (value) => validateTextInput(value, "Company Name", true, 70)
                },
                {
                    inputType: inputType.NUMBER,
                    labelText: "Partner ID",
                    required: true,
                    inputId: "partner_id",
                    validationFunction: () => {return null},
                    numberStep: "1"
                },
                {
                    inputType: inputType.TEXT,
                    labelText: "Lobby URL",
                    required: false,
                    inputId: "lobby_url",
                    validationFunction: (value) => validateTextInput(value, "Lobby URL", false, 255)
                },
                {
                    inputType: inputType.TEXT,
                    labelText: "API URL out",
                    required: false,
                    inputId: "api_url_out",
                    validationFunction: (value) => validateTextInput(value, "API URL out", false)
                },
                {
                    inputType: inputType.EMAIL,
                    labelText: "Email",
                    required: false,
                    inputId: "email",
                    validationFunction: (value) => validateTextInput(value, "Email", false)
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Type",
                    required: true,
                    inputId: "type",
                    validationFunction: () => {return null},
                    selectOptions: operatorTypeSelect
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Parent",
                    required: false,
                    inputId: "parent_id",
                    validationFunction: () => {return null},
                    selectOptions: formatArrayForSelectInput(operators, "company_name", true)
                }
            ])
        }
    }, [operators])

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"operator/update"}
            pageTitle={"Update Operator"}
            isUpdate={true}
            getEndpoint={"operator/read"}
            entityName={"Operator"} />
    )
}

export default OperatorUpdatePage;