import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function CountryDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Country Details"}
                            editHref={"/country/update"}
                            endpoint={"country/read"}
                            relations={["players", "players.language"]}
                            oneToManyRelations={[
                                {
                                    title: "Players",
                                    columnTitles: [
                                        "ID", "Operator User ID", "Language"
                                    ],
                                    name: "players",
                                    objectKeys: ["id", "operator_user_id", "language.name"],
                                    detailsHref: "/player/details"
                                }
                            ]} />
}

export default CountryDetailsPage;