export const styleType = {
    PRIMARY: 1,
    SECONDARY: 2,
    SUCCESS: 3,
    DANGER: 4,
    WARNING: 5,
    INFO: 6,
    LIGHT: 7,
    DARK: 8,
    PURPLE: 9,
    RED: 10,
    FADEBLACK: 11,
    FLEXPINK: 12
}