import React, { useEffect } from "react";
import { inputType } from "../../../Domain/Constants/InputType";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";

function RoleInsertPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);

    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true)
        },
        {
            inputType: inputType.TEXT,
            labelText: "Guard Name",
            required: true,
            inputId: "guard_name",
            validationFunction: (value) => validateTextInput(value, "Guard Name", true)
        }
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"role/create"}
            pageTitle={"Create Role"}
            isUpdate={false}
            onlyReadForAdmin={true} 
            entityName={"Role"} />
    )
}

export default RoleInsertPage;