import React, { useRef, useState } from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { deleteById } from "../../../Infrastructure/Services/DataService";
import UIModal from "../../../Domain/Components/UI/Modal/Modal";
import { toast } from "react-toastify";

function ProviderDetailsPage(props) {
    const [deleteProviderUserRoleModalOpen, setDeleteProviderUserRoleModalOpen] = useState(false);
    const [deleteProviderUserRoleId, setProviderUserRoleId] = useState(null);
    const detailsPageComponentRef = useRef({});

    const onDeleteProviderUserRoleClicked = (id) => {
        setProviderUserRoleId(id);
        setDeleteProviderUserRoleModalOpen(true);
    }

    const onCancelDelete = () => {
        setProviderUserRoleId(null);
        setDeleteProviderUserRoleModalOpen(false);
    }

    const deleteProviderUserRole = async () => {
        var token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        var result = await deleteById("provider-user-role/delete", deleteProviderUserRoleId, token);

        if (result) {
            setDeleteProviderUserRoleModalOpen(false);
            setProviderUserRoleId(null);
            detailsPageComponentRef.current.loadData();
        } else {
            toast.error("Failed to delete provider user role");
        }
    }

    return <> <GenericDetailsPage setLoggedIn={props.setLoggedIn}
        title={"Provider Details"}
        editHref={"/provider/update"}
        endpoint={"provider/read"}
        relations={["provider_user_roles", "provider_user_roles.user", "provider_user_roles.role", "game_configs", "game_configs.operator", "game_configs.provider"]}
        manyToManyRelations={[{
            name: "provider_user_roles",
            title: "User Roles",
            attachHref: {
                href: "/provider/user-role/attach",
                title: "Attach User Role"
            },
            columnTitles: ["User", "Role"],
            objectKeys: ["user.email", "role.name"],
            editHref: "/provider/user-role/update",
            detailsHref: "/provider/user-role/details",
            deleteClicked: onDeleteProviderUserRoleClicked
        }]}
        oneToManyRelations={[
            {
                title: "Game Configs",
                columnTitles: [
                    "ID", "Name", "Internal ID", "Operator", "Provider", "Provider ID"
                ],
                name: "game_configs",
                objectKeys: ["id", "name", "internal_game_id", "operator.company_name", "provider.name", "provider_game_id"],
                detailsHref: "/game-config/details"
            }
        ]}
        ref={detailsPageComponentRef} />

        <UIModal showModal={deleteProviderUserRoleModalOpen}
            onClose={onCancelDelete}
            onConfirm={deleteProviderUserRole}
            title={"Delete User Role"}
            body={"Are you sure you wnat to delete this provider user role?"}
            confirmButtonText={"Delete"}
        />
    </>
}

export default ProviderDetailsPage;