import { postApiCall } from "../Helpers/HttpHandler";
import { setWithExpiry } from "../../Domain/Helpers/LocalStorageHelper";

export const loginCall = async (email, pass) => {
    var result = await postApiCall("auth/login", {
        email: email,
        password: pass
    });

    if (result.data !== null) {
        setWithExpiry("token", result.data.token, process.env.REACT_APP_TOKEN_DURATION * 3600 * 1000);
        setWithExpiry("roles", result.data.roles, process.env.REACT_APP_TOKEN_DURATION * 3600 * 1000);
    }

    return result;
}

export const logoutCall = async(token) => {
    var result = await postApiCall("auth/logout", {}, token);

    if (result.data !== null) {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        return true;
    }

    return false;
}