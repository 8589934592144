import React from "react";
import { inputType } from "../../../Domain/Constants/InputType";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";

function LanguageInsertPage(props) {
    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true, 50)
        },
        {
            inputType: inputType.TEXT,
            labelText: "ISO 639 1",
            required: true,
            inputId: "iso_639_1",
            validationFunction: (value) => validateTextInput(value, "ISO 639 1", true, 2)
        },
        {
            inputType: inputType.TEXT,
            labelText: "ISO 639 3",
            required: true,
            inputId: "iso_639_3",
            validationFunction: (value) => validateTextInput(value, "ISO 639 3", true, 3)
        }
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"language/create"}
            pageTitle={"Create Language"}
            isUpdate={false}
            entityName={"Language"} />
    )
}

export default LanguageInsertPage;