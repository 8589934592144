import React, { useEffect, useState } from "react";
import { getAllActive, getById } from "../../../Infrastructure/Services/DataService";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function UserProviderUserRoleUpdatePage(props) {
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState(null);
    const [providers, setProviders] = useState(null);
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }

        const token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userId = params.get("entityId");

        getById("user/read", userId, token).then((data) => {
            if (data === null) {
                toast.error("Failed to load user");
            } else {
                setUser(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("provider/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load providers");
            } else {
                setProviders(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("role/read", token, true).then((data) => {
            if (data === null) {
                toast.error("Failed to load roles");
            } else {
                setRoles(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });
    }, []);

    useEffect(() => {
        if (providers && user && roles) {
            setColumns([
                {
                    inputType: inputType.SELECT,
                    labelText: "User",
                    required: true,
                    disabled: true,
                    inputId: "user_id",
                    validationFunction: () => {return null;},
                    selectOptions: formatArrayForSelectInput([user], "email")
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Provider",
                    required: true,
                    inputId: "provider_id",
                    selectOptions: formatArrayForSelectInput(providers, "name"),
                    validationFunction: () => {return null}
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Role",
                    required: true,
                    inputId: "role_id",
                    selectOptions: formatArrayForSelectInput(roles, "name"),
                    validationFunction: () => {return null}
                }
            ])
        }
    }, [providers, user, roles])

    return (
        <GenericInsertUpdatePage inputs={columns}
            setLoggedIn={props.setLoggedIn}
            endpoint={"provider-user-role/update"}
            pageTitle={"Update Provider User Role"}
            isUpdate={true}
            getEndpoint={"provider-user-role/read"}
            onlyReadForAdmin={true} />
    )
}

export default UserProviderUserRoleUpdatePage;