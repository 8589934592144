import React, { useRef, useState } from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { deleteById } from "../../../Infrastructure/Services/DataService";
import UIModal from "../../../Domain/Components/UI/Modal/Modal";

function PlayerDetailsPage(props) {
    const [deletePlayerCurrencyModalOpen, setDeletePlayerCurrencyModalOpen] = useState(false);
    const [deletePlayerCurrencyId, setPlayerCurrencyId] = useState(null);
    const detailsPageComponentRef = useRef({});

    const onDeletePlayerCurrencyClicked = (id) => {
        setPlayerCurrencyId(id);
        setDeletePlayerCurrencyModalOpen(true);
    }

    const onCancelDelete = () => {
        setPlayerCurrencyId(null);
        setDeletePlayerCurrencyModalOpen(false);
    }

    const deletePlayerCurrency = async () => {
        var token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        var result = await deleteById("player-currency/delete", deletePlayerCurrencyId, token);

        if (result) {
            setDeletePlayerCurrencyModalOpen(false);
            setPlayerCurrencyId(null);
            detailsPageComponentRef.current.loadData();
        }
    }

    return <> <GenericDetailsPage setLoggedIn={props.setLoggedIn}
        title={"Player Details"}
        editHref={"/player/update"}
        endpoint={"player/read"}
        relations={["player_currencies", "player_currencies.currency", "operator", "language", "country"]}
        displayIds={["operator_user_id"]}
        manyToManyRelations={[{
            name: "player_currencies",
            title: "Currencies",
            attachHref: {
                href: "/player/currency/attach",
                title: "Attach Currency"
            },
            columnTitles: ["ISO Code", "Real Balance", "Bonus Balance"],
            objectKeys: ["currency.iso_code", "real_balance", "bonus_balance"],
            editHref: "/player/currency/update",
            detailsHref: "/player-currency/details",
            deleteClicked: onDeletePlayerCurrencyClicked
        }]}
        ref={detailsPageComponentRef} />

        <UIModal showModal={deletePlayerCurrencyModalOpen}
            onClose={onCancelDelete}
            onConfirm={deletePlayerCurrency}
            title={"Delete Player Currency"}
            body={"Are you sure you wnat to delete this player currency?"}
            confirmButtonText={"Delete"}
        />
    </>
}

export default PlayerDetailsPage;