import React, { useEffect, useState } from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../Domain/Constants/StyleType";
import UIModal from "../../../Domain/Components/UI/Modal/Modal";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { deleteById, getAllActive } from "../../../Infrastructure/Services/DataService";
import { postApiCall } from "../../../Infrastructure/Helpers/HttpHandler";
import UILabelInput from "../../../Domain/Components/UI/LabelInput/LabelInput";
import { inputType } from "../../../Domain/Constants/InputType";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";

function UserDetailsPage(props) {
    const [attachRoleModalOpen, setAttachRoleModalOpen] = useState(false);
    const [roles, setRoles] = useState(null);
    const [failedToLoadRoles, setFailedToLoadRoles] = useState(false);

    const [deleteProviderUserRoleModalOpen, setDeleteProviderUserRoleModalOpen] = useState(false);
    const [deleteProviderUserRoleId, setProviderUserRoleId] = useState(null);

    const detailsPageComponentRef = useRef({});

    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);

    const onDeleteProviderUserRoleClicked = (id) => {
        setProviderUserRoleId(id);
        setDeleteProviderUserRoleModalOpen(true);
    }

    const onCancelDelete = () => {
        setProviderUserRoleId(null);
        setDeleteProviderUserRoleModalOpen(false);
    }

    const deleteProviderUserRole = async () => {
        var token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        var result = await deleteById("provider-user-role/delete", deleteProviderUserRoleId, token);

        if (result) {
            setDeleteProviderUserRoleModalOpen(false);
            setProviderUserRoleId(null);
            detailsPageComponentRef.current.loadData();
        } else {
            toast.error("Failed to delete provider user role");
        }
    }

    const openAttachModal = async () => {
        if (roles === null) {
            const token = getWithExpiry("token");
            if (!token) {
                props.setLoggedIn(false);
                return;
            }
            let result = await getAllActive("role/read", token, true);
            if (result === null) {
                setFailedToLoadRoles(true);
            } else {
                setRoles(result);
            }
        }

        setAttachRoleModalOpen(true);
    }

    const onAttach = async () => {
        let idsElement = document.getElementById("role_id");
        const token = getWithExpiry("token");
        if (!token) {
            props.setLoggedIn(false);
            return;
        }
        try {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            let id = params.get('id');
            var body = {
                userId: id,
                roleId: idsElement.value
            }
            var result = await postApiCall("user/attach-role", body, token);

            if (result.data) {
                setAttachRoleModalOpen(false);
                detailsPageComponentRef.current.loadData();
            } else {
                if (result.error) {
                    toast.error(result.error.message);
                } else {
                    toast.error("Failed to attach role");
                }
            }
        } catch (ex) {
            toast.error(ex);
        }
    }

    const onDetach = async () => {
        let idsElement = document.getElementById("detach-role-id");
        const token = getWithExpiry("token");
        if (!token) {
            props.setLoggedIn(false);
            return;
        }
        try {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            let id = params.get('id');
            var body = {
                userId: parseInt(id),
                roleId: idsElement.value.split(",")[0]
            }
            var result = await postApiCall("user/detach-role", body, token);

            if (result.data) {
                detailsPageComponentRef.current.loadData();
            } else {
                if (result.error) {
                    toast.error(result.error.message);
                } else {
                    toast.error("Failed to detach role");
                }
            }
        } catch (ex) {
            toast.error(ex);
        }
    }

    return <> <GenericDetailsPage setLoggedIn={props.setLoggedIn}
        title={"User Details"}
        editHref={"/user/update"}
        endpoint={"user/read"}
        withoutIsActiveFilter={true}
        relations={["roles", "provider_user_roles", "provider_user_roles.provider", "provider_user_roles.role"]}
        manyToManyRelations={[{
            name: "roles",
            title: "Roles",
            attachHref: {
                custom:<div> 
                    <UIButton styleType={styleType.FADEBLACK} buttonText={"Detach Selected Role"} buttonType={"button"} additionalClass="me-2" onClick={onDetach}/>
                    <UIButton styleType={styleType.FADEBLACK} buttonText={"Attach Role"} buttonType={"button"} onClick={openAttachModal} /> 
                </div>
            },
            columnTitles: ["Name", "Guard Name"],
            objectKeys: ["name", "guard_name"],
            detailsHref: "/role/details",
            includeCheckMarks: true,
            onlyOneSelected: true,
            checkMarksValueId: "detach-role-id"
        },
        {
            name: "provider_user_roles",
            title: "Provider User Roles",
            attachHref: {
                href: "/user/provider-role/attach",
                title: "Attach Provider User Role"
            },
            columnTitles: ["Provider", "Role"],
            objectKeys: ["provider.name", "role.name"],
            editHref: "/user/provider-role/update",
            detailsHref: "/provider/user-role/details",
            deleteClicked: onDeleteProviderUserRoleClicked
        }]}
        onlyReadForAdmin={true}
        ref={detailsPageComponentRef} />
        <UIModal showModal={attachRoleModalOpen}
            onClose={() => setAttachRoleModalOpen(false)}
            onConfirm={onAttach}
            title={"Attach Role"}
            body={<>
                {failedToLoadRoles ? <p className="text-danger">Failed to load available roles</p> :
                    <UILabelInput inputType={inputType.SELECT} 
                                inputId={"role_id"}
                                labelText={"Role"}
                                selectOptions={roles ? formatArrayForSelectInput(roles, "name") : []}
                                />
                }
            </>}
            confirmButtonText={"Attach"} />

<UIModal showModal={deleteProviderUserRoleModalOpen}
            onClose={onCancelDelete}
            onConfirm={deleteProviderUserRole}
            title={"Delete Provider User Role"}
            body={"Are you sure you wnat to delete this provider user role?"}
            confirmButtonText={"Delete"}
        />
    </>
}

export default UserDetailsPage;