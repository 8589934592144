import React, { useEffect } from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { useNavigate } from "react-router-dom";

function RoleListPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);
    
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            editHref={"/role/update"}
                            detailsHref={"/role/details"}
                            objectKeys={["id", "name", "guard_name"]}
                            columnTitles={[
                                "ID", "Name", "Guard Name"
                            ]}
                            insertHref={"/role/create"}
                            insertTitle={"Add Role"}
                            pageTitle={"Roles"}
                            readEndpoint={"role/read"}
                            disableDelete={true}
                            withoutIsActiveFilter={true}
                            onlyReadForAdmin={true}
                            />
        </>
    )
}

export default RoleListPage;