import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

function UISidebar(props) {
    const {pathname} = useLocation();

    const toggleSidebarDropdown = (e) => {
        e.target.nextSibling.classList.toggle('show');
    }

    return(
        <nav className="navbar navbar-inverse fixed-top" id="sidebar-wrapper" role="navigation">
        <ul className="nav sidebar-nav">
          <div className="sidebar-header">
          <div className="sidebar-brand">
            <img className="brand-logo" src={props.logoSrc} alt="brand">
            </img>
        </div> </div>
          {
            props.contents.map((val, i) => {
                return<React.Fragment key={i}> {
                    val.hidden ? null : <li className="dropdown" key={i}>
                    <a className="dropdown-toggle" href="#" role="button" onClick={(e) => toggleSidebarDropdown(e)}>
                        {val.submenuIcon} <span className="ms-1"></span> {val.submenuTitle} <span className="caret"></span></a>
                  <ul className="dropdown-menu animated fadeInLeft">
                   {
                    val.contents.map((contentVal, contentI) => {
                        return <li key={`${i}=${contentI}`} className={pathname === contentVal.path || pathname.includes(contentVal.path + "/") ? 'bg-flex-pink' : ''}>
                            {contentVal.linkEl}
                        </li>
                    })
                   }
                   </ul>
                   </li>
                }
                    
                </React.Fragment> 
            })
          }
         </ul>
     </nav>
    );
}

UISidebar.propTypes = {
    contents: PropTypes.arrayOf(PropTypes.object),
    logoSrc: PropTypes.string
}

export default UISidebar;