import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function GameTagListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Game Tag"}
                            deleteModalQuestion={"Are you sure you want to delete this game tag?"}
                            editHref={"/game-tag/update"}
                            detailsHref={"/game-tag/details"}
                            objectKeys={["id", "name"]}
                            columnTitles={[
                                "ID", "Name"
                            ]}
                            insertHref={"/game-tag/create"}
                            insertTitle={"Add Game Tag"}
                            pageTitle={"Game Tags"}
                            deleteEndpoint={"game-tag/delete"}
                            readEndpoint={"game-tag/read"}
                            />
        </>
    )
}

export default GameTagListPage;