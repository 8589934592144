import React, { useEffect } from "react";
import { inputType } from "../../../Domain/Constants/InputType";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";

function UserInsertPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);

    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true)
        },
        {
            inputType: inputType.EMAIL,
            labelText: "Email",
            required: true,
            inputId: "email",
            validationFunction: (value) => validateTextInput(value, "Email", true)
        },
        {
            inputType: inputType.PASSWORD,
            labelText: "Password",
            required: true,
            inputId: "password",
            validationFunction: (value) => validateTextInput(value, "Password", true)
        },
        {
            inputType: inputType.TEXT,
            labelText: "Guard",
            required: true,
            inputId: "guard",
            validationFunction: (value) => validateTextInput(value, "Guard", true, 40)
        },
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"user/create"}
            pageTitle={"Create User"}
            isUpdate={false}
            onlyReadForAdmin={true} 
            entityName={"User"} />
    )
}

export default UserInsertPage;