import React, { useEffect, useState } from "react";
import { getAllActive, getById } from "../../../Infrastructure/Services/DataService";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { toast } from "react-toastify";

function PlayerCurrencyUpdatePage(props) {
    const [currencies, setCurrencies] = useState(null);
    const [player, setPlayer] = useState(null);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const playerId = params.get("entityId");

        getById("player/read", playerId, token).then((data) => {
            if (data === null) {
                toast.error("Failed to load layer");
            } else {
                setPlayer(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("currency/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load currencies");
            } else {
                setCurrencies(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });
    }, []);

    useEffect(() => {
        if (player && currencies) {
            setColumns([
                {
                    inputType: inputType.SELECT,
                    labelText: "player",
                    required: true,
                    disabled: true,
                    inputId: "player_id",
                    validationFunction: () => {return null;},
                    selectOptions: formatArrayForSelectInput([player], "operator_user_id")
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Currency",
                    required: true,
                    inputId: "currency_id",
                    selectOptions: formatArrayForSelectInput(currencies, "iso_code"),
                    validationFunction: () => {return null}
                },
                {
                    inputType: inputType.NUMBER,
                    labelText: "Real Balance",
                    required: true,
                    inputId: "real_balance",
                    validationFunction: () => {return null},
                    numberStep: "0.00000001"
                },
                {
                    inputType: inputType.NUMBER,
                    labelText: "Bonus Balance",
                    required: true,
                    inputId: "bonus_balance",
                    validationFunction: () => {return null},
                    numberStep: "0.00000001"
                }
            ])
        }
    }, [player, currencies])

    return (
        <GenericInsertUpdatePage inputs={columns}
            setLoggedIn={props.setLoggedIn}
            endpoint={"player-currency/update"}
            pageTitle={"Update Player Currency"}
            isUpdate={true}
            getEndpoint={"player-currency/read"} />
    )
}

export default PlayerCurrencyUpdatePage;