import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function GameConfigDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Game Config Details"}
                            editHref={"/game-config/update"}
                            endpoint={"game-config/read"}
                            relations={["operator", "provider", "game_tag"]} />
}

export default GameConfigDetailsPage;