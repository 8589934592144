import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function PlayerCurrencyDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Player Currency Details"}
                            endpoint={"player-currency/read"}
                            relations={["player", "currency"]} />
}

export default PlayerCurrencyDetailsPage;