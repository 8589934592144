import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function LanguageDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Language Details"}
                            editHref={"/language/update"}
                            endpoint={"language/read"}
                            relations={["players", "players.country"]}
                            oneToManyRelations={[
                                {
                                    title: "Players",
                                    columnTitles: [
                                        "ID", "Operator User ID", "Country"
                                    ],
                                    name: "players",
                                    objectKeys: ["id", "operator_user_id", "country.name"],
                                    detailsHref: "/player/details"
                                }
                            ]} />
}

export default LanguageDetailsPage;