import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function CurrencyRateListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Currency Rate"}
                            deleteModalQuestion={"Are you sure you want to delete this currency rate?"}
                            editHref={"/currency-rate/update"}
                            detailsHref={"/currency-rate/details"}
                            objectKeys={["id", "currency_from.name", "currency_to.name", "value", "date"]}
                            columnTitles={[
                                "ID", "From", "To", "Value", "Date"
                            ]}
                            insertHref={"/currency-rate/create"}
                            insertTitle={"Add Currency Rate"}
                            pageTitle={"Currency Rates"}
                            deleteEndpoint={"currency-rate/delete"}
                            readEndpoint={"currency-rate/read"}
                            relations={["currency_from", "currency_to"]}
                            />
        </>
    )
}

export default CurrencyRateListPage;