import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types"
import { getWithExpiry } from "../../../Helpers/LocalStorageHelper";
import { getById } from "../../../../Infrastructure/Services/DataService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { createdUpdatedAtValueFormat, formatColumnNames } from "../../../Helpers/StringFormatHelper";
import UITable from "../../UI/Table/Table";
import { toast } from "react-toastify";

const GenericDetailsPage = forwardRef((props, ref) => {
    const [data, setData] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [onlyRead, setOnlyRead] = useState(false);

    const loadData = () => {
        var token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        const search = window.location.search;
        const params = new URLSearchParams(search);
        let id = params.get('id');

        getById(props.endpoint, id, token, props.relations, props.withoutIsActiveFilter).then((data) => {
            if (data === null) {
                setNotFound(true);
            } else {
                setData(data);
            }
        }).catch((ex) => {
            toast.error(ex);
            setNotFound(true);
        })
    }

    useEffect(() => {
        const roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }

        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0 || 
        (!roles.includes("super-admin") && props.onlyReadForAdmin)) {
            setOnlyRead(true);
        }

        loadData();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            loadData
        }
    })

    return (
        <>
            <div className="d-flex justify-content-between mt-2">
                <p className="h3">{props.title}</p>
                <div>
                    {props.additionalButtons}
                    {props.editHref && data && !onlyRead && <Link to={`${props.editHref}?id=${data.id}`} className="btn btn-fadeblack"><FontAwesomeIcon icon={faPen} /></Link>}
                </div>
            </div>

            <div className="mt-2">
                {notFound &&
                    <p className="text-danger h4">Entity not found</p>
                }

                {
                    !notFound && data &&
                    <>
                        <ul className="list-group">
                            {Object.entries(data).map((entry, index) => {
                                return <React.Fragment key={index}>
                                    {
                                        (!entry[0].includes("_id") ||(props.displayIds && props.displayIds.includes(entry[0]))) && 
                                        ((props.manyToManyRelations && props.manyToManyRelations.filter(x => x.name === entry[0]).length === 0) || !props.manyToManyRelations) &&
                                        ((props.oneToManyRelations && props.oneToManyRelations.filter(x => x.name === entry[0]).length === 0) || !props.oneToManyRelations)
                                        && <li className="list-group-item d-flex justify-content-between align-items-start" key={index}>
                                            <div className="ms-2 me-auto w-100">
                                                <span className="fw-bold me-2">{formatColumnNames(entry[0])}:</span>
                                                {typeof (entry[1]) !== "object" ? <>{entry[0] === "created_at" || entry[0] === "updated_at" ? createdUpdatedAtValueFormat(entry[1]) : isNaN(Number(entry[1])) ? entry[1].toString() : Number(entry[1])}</> : entry[1] &&
                                                    <ul className="list-group w-100">
                                                        {Object.entries(entry[1]).map((nestedVal, nestedIndex) => {
                                                            return <li className="list-group-item" key={`${index}-${nestedIndex}`}>
                                                                {formatColumnNames(nestedVal[0])}: {nestedVal[1]?.toString()}
                                                            </li>
                                                        })}
                                                    </ul>
                                                }
                                            </div>
                                        </li>
                                    }
                                </React.Fragment>
                            })}
                        </ul>
                        {props.manyToManyRelations && <>
                            {props.manyToManyRelations.map((rel, index) => {
                                return <React.Fragment key={`rel-${index}`}>
                                    <div className="d-flex justify-content-between mt-4 mb-2">
                                        <p className="h4">{rel.title}</p>
                                        {onlyRead ? <span></span> : rel.attachHref && rel.attachHref.custom ? rel.attachHref.custom : <Link to={rel.attachHref.href + "?entityId=" + data.id} className="btn btn-fadeblack">{rel.attachHref.title}</Link>}
                                    </div>
                                    {data[rel.name].length > 0 ? 
                                    <UITable columnTitles={rel.columnTitles}
                                        data={props.withoutIsActiveFilter ? data[rel.name] : data[rel.name].filter(x => x.is_active === 1)}
                                        objectKeys={rel.objectKeys}
                                        primaryKey={'id'}
                                        lastElementRef={null}
                                        editHref={rel.editHref && !onlyRead ? rel.editHref + "?entityId=" + data.id : null}
                                        detailsHref={rel.detailsHref}
                                        deleteClicked={rel.deleteClicked}
                                        preventDelete={!rel.deleteClicked || onlyRead}
                                        includeCheckMarks={rel.includeCheckMarks && !onlyRead}
                                        checkMarksValueId={rel.checkMarksValueId}
                                        onlyOneSelected={rel.onlyOneSelected}
                                    ></UITable> : 
                                    <div className="alert alert-secondary" role="alert">No {rel.title} affiliated with the current {props.title.split(" Details")[0]}</div>}
                                </React.Fragment>
                            })}
                        </>}
                        {props.oneToManyRelations && <>
                            {props.oneToManyRelations.map((rel, index) => {
                                return <React.Fragment key={`rel-mo-${index}`}>
                                    <div className="d-flex justify-content-between mt-4 mb-2">
                                        <p className="h4">{rel.title}</p>            
                                    </div>
                                    {data[rel.name].length > 0 ? 
                                    <UITable columnTitles={rel.columnTitles}
                                        data={data[rel.name].filter(x => x.is_active === 1)}
                                        objectKeys={rel.objectKeys}
                                        primaryKey={'id'}
                                        lastElementRef={null}
                                        detailsHref={rel.detailsHref}
                                        preventDelete={true}
                                    ></UITable> : 
                                    <div className="alert alert-secondary" role="alert">No {rel.title} affiliated with the current {props.title.split(" Details")[0]}</div>}
                                </React.Fragment>
                            })}
                        </>}
                    </>
                }
            </div>
        </>
    )
})

GenericDetailsPage.propTypes = {
    setLoggedIn: PropTypes.func,
    endpoint: PropTypes.string,
    title: PropTypes.string,
    editHref: PropTypes.string,
    relations: PropTypes.arrayOf(PropTypes.string),
    withoutIsActiveFilter: PropTypes.bool,
    manyToManyRelations: PropTypes.arrayOf(PropTypes.object),
    oneToManyRelations: PropTypes.arrayOf(PropTypes.object),
    onlyReadForAdmin: PropTypes.bool
}

export default GenericDetailsPage;