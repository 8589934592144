import React from "react";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import { inputType } from "../../../Domain/Constants/InputType";

function GameTagUpdatePage(props) {
    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true, 50)
        }
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"game-tag/update"}
            pageTitle={"Update Game Tag"}
            isUpdate={true}
            getEndpoint={"game-tag/read"}
            entityName={"Game Tag"} />
    )
}

export default GameTagUpdatePage;