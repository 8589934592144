export async function postApiCall(endpoint, body = null, token = null)
{
    let baseUrl = process.env.REACT_APP_API_BASE_URL;
    let url = new URL(`${baseUrl}${endpoint}`);
    let settings = {
        method: "POST",
        headers: token !== null ? {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
        } : {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: body ? JSON.stringify(body) : null
    }
    
    let response = await fetch(url, settings);
    let data = await response.json();

    return data;
}