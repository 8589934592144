import React from "react";
import { inputType } from "../../../Domain/Constants/InputType";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";

function ProviderUpdatePage(props) {
    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true)
        },
        {
            inputType: inputType.NUMBER,
            labelText: "Agregator ID",
            required: false,
            inputId: "agregator_id",
            numberStep: "1",
            numberMin: "0",
            validationFunction: (value) => validateTextInput(value, "Agregator ID", false)
        },
        {
            inputType: inputType.TEXT,
            labelText: "Game Launch URL",
            required: true,
            inputId: "game_launch_url",
            validationFunction: (value) => validateTextInput(value, "Game Launch URL", true)
        }
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"provider/update"}
            pageTitle={"Update Provider"}
            isUpdate={true}
            getEndpoint={"provider/read"}
            entityName={"Provider"} />
    )
}

export default ProviderUpdatePage;