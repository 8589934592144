import React from "react";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import { inputType } from "../../../Domain/Constants/InputType";

function CurrencyUpdatePage(props) {
    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "ISO Code",
            required: true,
            inputId: "iso_code",
            validationFunction: (value) => validateTextInput(value, "ISO Code", true, 3)
        },
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true, 30)
        },
        {
            inputType: inputType.TEXT,
            labelText: "Symbol",
            required: true,
            inputId: "symbol",
            validationFunction: (value) => validateTextInput(value, "Symbol", true, 3)
        },
        {
            inputType: inputType.TEXT,
            labelText: "Subunit",
            required: true,
            inputId: "subunit",
            validationFunction: (value) => validateTextInput(value, "Subunit", true, 20)
        },
        {
            inputType: inputType.NUMBER,
            labelText: "Subunit to unit",
            required: true,
            inputId: "subunit_to_unit",
            numberStep: "1",
            validationFunction: () => {return null;}
        },
        {
            inputType: inputType.NUMBER,
            labelText: "ISO Numeric",
            required: true,
            inputId: "iso_numeric",
            numberStep: "1",
            validationFunction: () => {return null;}
        }
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"currency/update"}
            pageTitle={"Update Currency"}
            isUpdate={true}
            getEndpoint={"currency/read"}
            entityName={"Currency"} />
    )
}

export default CurrencyUpdatePage;