import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function GameConfigListPage(props) {
    return(
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Game Config"}
                            deleteModalQuestion={"Are you sure you want to delete this game config?"}
                            editHref={"/game-config/update"}
                            detailsHref={"/game-config/details"}
                            objectKeys={["id", "name", "internal_game_id", "operator.company_name", "provider.name", "provider_game_id"]}
                            columnTitles={[
                                "ID", "Name", "Internal ID", "Operator", "Provider", "Provider ID"
                            ]}
                            insertHref={"/game-config/create"}
                            insertTitle={"Add Game Config"}
                            pageTitle={"Game Configs"}
                            deleteEndpoint={"game-config/delete"}
                            readEndpoint={"game-config/read"}
                            relations={["operator", "provider"]}
                            />
        </>
    )
}

export default GameConfigListPage;