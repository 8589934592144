import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function PlayerListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Player"}
                            deleteModalQuestion={"Are you sure you want to delete this player?"}
                            editHref={"/player/update"}
                            detailsHref={"/player/details"}
                            objectKeys={["id", "operator_user_id", "operator.company_name", "country.name", "language.name"]}
                            columnTitles={[
                                "ID", "Operator User ID", "Operator", "Country", "Language"
                            ]}
                            insertHref={"/player/create"}
                            insertTitle={"Add Player"}
                            pageTitle={"Players"}
                            deleteEndpoint={"player/delete"}
                            readEndpoint={"player/read"}
                            relations={["country", "language", "operator"]}
                            />
        </>
    )
}

export default PlayerListPage;