import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function CountryListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Country"}
                            deleteModalQuestion={"Are you sure you want to delete this country?"}
                            editHref={"/country/update"}
                            detailsHref={"/country/details"}
                            objectKeys={["id", "name", "code"]}
                            columnTitles={[
                                "ID", "Name", "Code"
                            ]}
                            insertHref={"/country/create"}
                            insertTitle={"Add Country"}
                            pageTitle={"Countries"}
                            deleteEndpoint={"country/delete"}
                            readEndpoint={"country/read"}
                            />
        </>
    )
}

export default CountryListPage;