import React, { useEffect } from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";

function PermissionDetailsPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        var roles = getWithExpiry("roles");
        if (!roles) {
            props.setLoggedIn(false);
            return;
        }
        if (roles.filter(x => x === "admin" || x === "super-admin").length === 0) {
            navigate(-1);
        }
    }, []);

    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Permission Details"}
                            endpoint={"permission/read"}
                            withoutIsActiveFilter={true}
                            onlyReadForAdmin={true} />
}

export default PermissionDetailsPage;