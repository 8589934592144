import React, { useRef } from "react";
import PropTypes from "prop-types";
import UIButton from "../Button/Button";
import { styleType } from "../../../Constants/StyleType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";

function UISearchBar(props) {
    var searchRef = useRef({});
    const onFormSubmit = (e) => {
        e.preventDefault();
        props.onSearch(searchRef.current.value);
    }
    return (
        <div className="d-flex justify-content-start">
            <form className="form-inline" onSubmit={onFormSubmit}>
                <div id="searchInput" className="row">
                    <div className="col-10">
                    <input className="form-control" type="search" placeholder="Search..." aria-label="Search" 
                    defaultValue={props.string} ref={searchRef} onChange={props.onInputChange}/>
                    </div>
                    <div className="col-2">
                    <UIButton icon={<FontAwesomeIcon icon={faMagnifyingGlass}/>} buttonType="submit" isOutline={false} styleType={styleType.FLEXPINK} ></UIButton>
                    </div>
                </div>
            </form>
        </div>
    )
}

UISearchBar.propTypes = {
    string: PropTypes.string,
    onSearch: PropTypes.func,
    onInputChange: PropTypes.func
};

export default UISearchBar;