import React from "react";
import GenericDetailsPage from "../../../Domain/Components/Functional/GenericDetailsPage/GenericDetailsPage";

function ProviderUserRoleDetailsPage(props) {
    return <GenericDetailsPage setLoggedIn={props.setLoggedIn}
                            title={"Provider User Role Details"}
                            endpoint={"provider-user-role/read"}
                            relations={["provider", "user", "role"]} />
}

export default ProviderUserRoleDetailsPage;