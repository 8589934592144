import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types"

function UINavbar(props) {
    const {pathname} = useLocation();
    return (
        <nav className="navbar navbar-light bg-flex-pink">
            <div className="container-fluid">
                <span className="navbar-brand mb-0 h1 text-uppercase text-light"> 
                    <span className="me-2"><FontAwesomeIcon icon={faBars} onClick={props.toggleSidebar}/></span> 
                    {pathname.split("/")[1].replace("-", " ")}
                </span>
            </div>
        </nav>
    )
}

UINavbar.propTypes = {
    toggleSidebar: PropTypes.func
}

export default UINavbar;