import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function OperatorListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Operator"}
                            deleteModalQuestion={"Are you sure you want to delete this operator?"}
                            editHref={"/operator/update"}
                            detailsHref={"/operator/details"}
                            objectKeys={["id", "company_name", "partner_id", "type", "lobby_url", "email", "operator.company_name"]}
                            columnTitles={[
                                "ID", "Company Name", "Partner ID", "Type", "Lobby URL", "Email", "Operator"
                            ]}
                            insertHref={"/operator/create"}
                            insertTitle={"Add Operator"}
                            pageTitle={"Operators"}
                            deleteEndpoint={"operator/delete"}
                            readEndpoint={"operator/read"}
                            relations={["operator"]}
                            />
        </>
    )
}

export default OperatorListPage;