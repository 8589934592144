import React, { useEffect, useState } from "react";
import { getAllActive } from "../../../Infrastructure/Services/DataService";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { formatArrayForSelectInput } from "../../../Domain/Helpers/SelectInputHelper";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import { inputType } from "../../../Domain/Constants/InputType";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";
import { toast } from "react-toastify";

function GameConfigUpdatePage(props) {
    const [providers, setProviders] = useState(null);
    const [operators, setOperators] = useState(null);
    const [gameTags, setGameTags] = useState(null);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const token = getWithExpiry("token");

        if (token === null) {
            props.setLoggedIn(false);
            return;
        }

        getAllActive("provider/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load providers");
            } else {
                setProviders(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("operator/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load operators");
            } else {
                setOperators(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });

        getAllActive("game-tag/read", token).then((data) => {
            if (data === null) {
                toast.error("Failed to load game tags");
            } else {
                setGameTags(data);
            }
        }).catch((ex) => {
            toast.error(ex);
        });
    }, []);

    useEffect(() => {
        if (operators && providers && gameTags) {
            setColumns([
                {
                    inputType: inputType.TEXT,
                    labelText: "Name",
                    required: true,
                    inputId: "name",
                    validationFunction: (value) => validateTextInput(value, "Name", true)
                },
                {
                    inputType: inputType.TEXT,
                    labelText: "Internal Game ID",
                    required: true,
                    inputId: "internal_game_id",
                    validationFunction: (value) => validateTextInput(value, "Internal Game ID", true, 40)
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Operator",
                    required: true,
                    inputId: "operator_id",
                    selectOptions: formatArrayForSelectInput(operators, "company_name"),
                    validationFunction: () => {return null}
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Provider",
                    required: true,
                    inputId: "provider_id",
                    selectOptions: formatArrayForSelectInput(providers, "name"),
                    validationFunction: () => {return null}
                },
                {
                    inputType: inputType.TEXT,
                    labelText: "Provider Game ID",
                    required: true,
                    inputId: "provider_game_id",
                    validationFunction: (value) => validateTextInput(value, "Provider Game ID", true, 200),
                },
                {
                    inputType: inputType.SELECT,
                    labelText: "Game Tag",
                    required: true,
                    inputId: "game_tag_id",
                    selectOptions: formatArrayForSelectInput(gameTags, "name"),
                    validationFunction: () => {return null}
                }
            ])
        }
    }, [operators, providers, gameTags])

    return (
        <GenericInsertUpdatePage inputs={columns}
            setLoggedIn={props.setLoggedIn}
            endpoint={"game-config/update"}
            pageTitle={"Update Game Config"}
            isUpdate={true}
            getEndpoint={"game-config/read"}
            entityName={"Game Config"} />
    )
}

export default GameConfigUpdatePage;