import React, { useState } from "react";
import PropTypes from "prop-types"

function UIMultiselect(props) {
    const [selectedKeys, setSelectedKeys] = useState([]);

    const selectValue = (key) => {
        let tmpSelectedKeys = structuredClone(selectedKeys);
        if (selectedKeys.includes(key)) {
            const i = tmpSelectedKeys.indexOf(key);
            tmpSelectedKeys.splice(i, 1);
        } else {
            tmpSelectedKeys.push(key);
        }

        setSelectedKeys(tmpSelectedKeys);
    }

    return <>
        <input type="hidden" name={props.id} id={props.id} value={selectedKeys} />
        <div className="list-group">
            {
                props.options.map((opt, i) => {
                    return <button key={i} className={`list-group-item list-group-item-action ${selectedKeys.includes(opt.id) ? "active" : ""}`}
                                onClick={() => selectValue(opt.id)}>{opt[props.displayKey]}</button>
                })
            }
        </div>
    </>
}

UIMultiselect.propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    displayKey: PropTypes.string
}

export default UIMultiselect;