import React from "react";
import { inputType } from "../../../Domain/Constants/InputType";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";

function GameTagInsertPage(props) {
    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true, 50)
        }
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"game-tag/create"}
            pageTitle={"Create Game Tag"}
            isUpdate={false}
            entityName={"Game Tag"} />
    )
}

export default GameTagInsertPage;