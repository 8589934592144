import React from "react";
import PropTypes from 'prop-types';
import { styleType } from "../../../Constants/StyleType";
import "./style.css";

const UIButton = (props) => {
    return (
        <button
        type={props.buttonType}
        className={`btn ${props.isOutline ? "btn-outline-" : "btn-"}${Object.keys(styleType).find(key => styleType[key] === props.styleType).toLowerCase()} ${props.additionalClass ? props.additionalClass : ''} ${props.disabled ? 'disabled' : ''}`}
        onClick={props.onClick}>                
            {props.icon ? props.icon : props.buttonText}
        </button>
    );
};

UIButton.propTypes = {
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    styleType: PropTypes.number,
    disabled: PropTypes.bool,
    buttonType: PropTypes.string,
    isOutline: PropTypes.bool
};

export default UIButton;