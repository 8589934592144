import React from "react";
import { inputType } from "../../../Domain/Constants/InputType";
import { validateTextInput } from "../../../Domain/Helpers/InputValidationHelper";
import GenericInsertUpdatePage from "../../../Domain/Components/Functional/GenericInsertPage/GenericInsertPage";

function CountryInsertPage(props) {
    const inputs = [
        {
            inputType: inputType.TEXT,
            labelText: "Name",
            required: true,
            inputId: "name",
            validationFunction: (value) => validateTextInput(value, "Name", true, 50),
        },
        {
            inputType: inputType.TEXT,
            labelText: "Code",
            required: true,
            inputId: "code",
            validationFunction: (value) => validateTextInput(value, "Code", true, 2)
        }
    ];

    return (
        <GenericInsertUpdatePage inputs={inputs}
            setLoggedIn={props.setLoggedIn}
            endpoint={"country/create"}
            pageTitle={"Create Country"}
            isUpdate={false}
            entityName={"Country"} />
    )
}

export default CountryInsertPage;