import React from "react";
import GenericListPage from "../../../Domain/Components/Functional/GenericListPage/GenericListPage";

function LanguageListPage(props) {
    return (
        <>
            <GenericListPage setLoggedIn={props.setLoggedIn}
                            deleteModalTitile={"Delete Language"}
                            deleteModalQuestion={"Are you sure you want to delete this language?"}
                            editHref={"/language/update"}
                            detailsHref={"/language/details"}
                            objectKeys={["id", "name", "iso_639_1", "iso_639_3"]}
                            columnTitles={[
                                "ID", "Name", "ISO 639 1", "ISO 638 3"
                            ]}
                            insertHref={"/language/create"}
                            insertTitle={"Add Language"}
                            pageTitle={"Languages"}
                            deleteEndpoint={"language/delete"}
                            readEndpoint={"language/read"}
                            />
        </>
    )
}

export default LanguageListPage;